import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import CardBody from "../../common/CardBody";
import ProductImage from "../../common/ProductImage";
import DeleteButton from "../../common/DeleteButton";
import { Form } from "react-bootstrap";
import EditButton from "../../common/EditButton";

const BankListBox = ({
      loading,
      items,
      totalItems,
      perPages,
      setLimit,
      currentPage,
      getData,
      loadData,
      setSearch,
      setBanks,
      setLoading,
}) => {
      const token = JSON.parse(localStorage.getItem("token"));

      const handleStatus = async (category) => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/banks/${category.slug}/status`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (blog) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/banks/${blog.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBanks((agents) =>
                                    agents.filter((item) => item.id !== blog.id)
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <CardBody
                        totalItems={totalItems}
                        perPages={perPages}
                        setLimit={setLimit}
                        currentPage={currentPage}
                        getData={getData}
                        setSearch={setSearch}
                        borderStyle={true}
                  >
                        <thead>
                              <tr>
                                    <th width="50px">#</th>
                                    <th>Bank Name</th>
                                    <th>Address</th>
                                    <th>Account Name </th>
                                    <th>Account No. </th>
                                    <th>Ifsc Code</th>
                                    <th width="100px">Status</th>
                                    <th width="100px">Action</th>
                              </tr>
                        </thead>

                        <tbody>
                              {!loading ? (
                                    items.length > 0 ? (
                                          items.map((blog, index) => (
                                                <>
                                                      <tr key={index}>
                                                            <td>
                                                                  {blog.index}
                                                            </td>
                                                            <td>
                                                                  <ProductImage
                                                                        title={
                                                                              blog.bank_name
                                                                        }
                                                                  />
                                                            </td>
                                                            <td>
                                                                  <ProductImage
                                                                        title={
                                                                              blog.address
                                                                        }
                                                                  />
                                                            </td>
                                                            <td>
                                                                  {
                                                                        blog?.account_name
                                                                  }
                                                            </td>
                                                            <td>
                                                                  {
                                                                        blog?.account_number
                                                                  }
                                                            </td>
                                                            <td>
                                                                  {
                                                                        blog?.ifsc_code
                                                                  }
                                                            </td>
                                                            <td className="text-center">
                                                                  <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        defaultChecked={
                                                                              blog.status ===
                                                                              1
                                                                                    ? true
                                                                                    : false
                                                                        }
                                                                        onClick={() =>
                                                                              handleStatus(
                                                                                    blog
                                                                              )
                                                                        }
                                                                  />
                                                            </td>
                                                            <td className="text-center">
                                                                  <EditButton
                                                                        link={`/dashboard/banks/${blog.slug}/edit`}
                                                                        classes="me-2"
                                                                  />
                                                                  <DeleteButton
                                                                        item={
                                                                              blog
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        setLoading={
                                                                              setLoading
                                                                        }
                                                                        handleDelete={
                                                                              handleDelete
                                                                        }
                                                                  />
                                                            </td>
                                                      </tr>
                                                      <tr>
                                                            <td
                                                                  colSpan={8}
                                                                  className="list-td text-warning"
                                                            >
                                                                  Note:{" "}
                                                                  {blog?.note}
                                                            </td>
                                                      </tr>
                                                </>
                                          ))
                                    ) : (
                                          <>
                                                <tr>
                                                      <td
                                                            colSpan={7}
                                                            className="text-center"
                                                      >
                                                            No Request Found
                                                      </td>
                                                </tr>
                                          </>
                                    )
                              ) : (
                                    <>
                                          <tr>
                                                <td
                                                      colSpan={7}
                                                      className="text-center"
                                                >
                                                      Loading...
                                                </td>
                                          </tr>
                                    </>
                              )}
                        </tbody>
                  </CardBody>
            </>
      );
};

export default BankListBox;
