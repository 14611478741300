import { styled } from "styled-components";

export const SearchContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      font-size: 14px;

      input,
      select {
            font-size: 14px;
      }
`;

export const SearchEntity = styled.div`
      display: flex;
      align-items: center;
`;
