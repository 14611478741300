import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../backend/dashboard";

import {
      BannerList,
      BannerCreate,
      BannerEdit,
      BannerShow,
} from "../backend/banner";

import { PageList, PageCreate, PageEdit, PageShow } from "../backend/pages";

import PageNotFound from "../components/PageNotFound";
import {
      AirlineCreate,
      AirlineEdit,
      AirlineList,
      AirlineShow,
} from "../backend/airlines";
import { tourManages } from "./TourManage";
import { userManages } from "./UserManage";
import { bankManages } from "./BankManage";
import { agentManages } from "./AgentManage";

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Airline */}
                        <Route
                              path="/dashboard/airlines"
                              element={<AirlineList />}
                        />
                        <Route
                              path="/dashboard/airlines/create"
                              element={<AirlineCreate />}
                        />
                        <Route
                              path="/dashboard/airlines/:airlineId/edit"
                              element={<AirlineEdit />}
                        />
                        <Route
                              path="/dashboard/airlines/:airlineId/show"
                              element={<AirlineShow />}
                        />

                        {/* Agent Manage */}
                        {agentManages}

                        {/* Bank Manage */}
                        {bankManages}

                        {/* Tour Manage */}
                        {tourManages}

                        {/* Banner */}
                        <Route
                              path="/dashboard/banners"
                              element={<BannerList />}
                        />
                        <Route
                              path="/dashboard/banners/create"
                              element={<BannerCreate />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/edit"
                              element={<BannerEdit />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/show"
                              element={<BannerShow />}
                        />

                        {/* Page */}
                        <Route path="/dashboard/pages" element={<PageList />} />
                        <Route
                              path="/dashboard/pages/create"
                              element={<PageCreate />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/edit"
                              element={<PageEdit />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/show"
                              element={<PageShow />}
                        />

                        {userManages}

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
