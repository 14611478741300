import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Navigate } from "react-router-dom";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FormBox, FormButton, FormContainer, FormFooter } from "./styles";

const Login = () => {
      // Set Variable
      const [email, setEmail] = useState("");
      const [password, setPassword] = useState("");
      const [isLoading, setIsLoading] = useState(false);
      const [redirect, setRedirect] = useState(false);

      async function handleForm(event) {
            event.preventDefault();
            setIsLoading(true);

            await axios
                  .post(`${process.env.REACT_APP_SECRET_KEY}/api/auth/login`, {
                        email: email,
                        password: password,
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success("You are successfully Loggedin");
                              localStorage.setItem("isLoggedIn", true);
                              localStorage.setItem(
                                    "token",
                                    JSON.stringify(
                                          response.data.token.original.token
                                    )
                              );
                              localStorage.setItem(
                                    "tokenExpiration",
                                    new Date(
                                          new Date().getTime() +
                                                response.data.token.original
                                                      .token_validity *
                                                      1000
                                    )
                              );
                              setRedirect(true);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message) {
                                    toast.error(response.data.message);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.password) {
                                    toast.error(response.data.message.password);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setIsLoading(false);
      }

      if (redirect) {
            return <Navigate to="/dashboard" />;
      }
      const login = localStorage.getItem("isLoggedIn");
      if (login) {
            return <Navigate to="/dashboard" />;
      }

      return (
            <>
                  <FormContainer>
                        <Container>
                              <Row className="g-0">
                                    <Col lg={5} md={6}>
                                          <FormBox>
                                                <div className="text-center mb-3">
                                                      <img
                                                            src="/images/logo.png"
                                                            alt="Aircasa Official Logo"
                                                            style={{
                                                                  height: "60px",
                                                                  objectFit:
                                                                        "contain",
                                                            }}
                                                            className="mb-3"
                                                      />
                                                </div>
                                                <Form
                                                      onSubmit={handleForm}
                                                      className="row g-3"
                                                >
                                                      <Form.Group>
                                                            <Form.Label>
                                                                  Email Address
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="email"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setEmail(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Form.Group>

                                                      <Form.Group>
                                                            <Form.Label>
                                                                  Password
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="password"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setPassword(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Form.Group>

                                                      <Form.Group>
                                                            <FormButton>
                                                                  <button
                                                                        type="submit"
                                                                        className="text-uppercase w-100 mb-2"
                                                                  >
                                                                        log in
                                                                        {isLoading && (
                                                                              <Spinner
                                                                                    style={{
                                                                                          height: "18px",
                                                                                          width: "18px",
                                                                                          marginLeft:
                                                                                                "6px",
                                                                                    }}
                                                                                    animation="border"
                                                                                    role="status"
                                                                              >
                                                                                    <span className="visually-hidden">
                                                                                          Loading...
                                                                                    </span>
                                                                              </Spinner>
                                                                        )}
                                                                  </button>
                                                            </FormButton>
                                                      </Form.Group>
                                                </Form>
                                                <form>
                                                      <div className="form_content">
                                                            <div className="form_options d-flex justify-content-between mb-2">
                                                                  <a href="/forget-password">
                                                                        Forget
                                                                        Password
                                                                  </a>
                                                            </div>
                                                      </div>

                                                      <FormFooter>
                                                            Powered by &nbsp;
                                                            <a
                                                                  href="https://infinityinfosys.com"
                                                                  target="_blank"
                                                                  rel="noreferrer"
                                                            >
                                                                  {" "}
                                                                  Infinity
                                                                  Infosys Pvt.
                                                                  Ltd.
                                                            </a>
                                                      </FormFooter>
                                                </form>
                                          </FormBox>
                                    </Col>
                              </Row>
                        </Container>
                  </FormContainer>
            </>
      );
};

export default Login;
