import React, { useState } from "react";
import axios from "axios";
import $ from "jquery";
import { toast } from "react-hot-toast";

import { Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import FormInput from "../../components/form/FormInput";
import {
      FormButton,
      FormStatusSelect,
      FormTextarea,
} from "../../components/form";

const BankCreate = () => {
      const [bankName, setBankName] = useState("");
      const [address, setAddress] = useState("");
      const [accountName, setAccountName] = useState("");
      const [accountNumber, setAccountNumber] = useState("");
      const [ifscCode, setIfscCode] = useState("");
      const [note, setNote] = useState("");
      const [status, setStatus] = useState("");
      const [loading, setLoading] = useState(false);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  bank_name: bankName,
                  address: address,
                  account_name: accountName,
                  account_number: accountNumber,
                  ifsc_code: ifscCode,
                  note: note,
                  status: status,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/bank_lists`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              $("form").trigger("reset");
                              toast.success(response.data.message);
                        }

                        if (response.data.result === "error") {
                              if (response.data.message.bank_name) {
                                    toast.error(
                                          response.data.message.bank_name
                                    );
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.account_name) {
                                    toast.error(
                                          response.data.message.account_name
                                    );
                              }
                              if (response.data.message.account_number) {
                                    toast.error(
                                          response.data.message.account_number
                                    );
                              }
                              if (response.data.message.ifsc_code) {
                                    toast.error(
                                          response.data.message.ifsc_code
                                    );
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.note) {
                                    toast.error(response.data.message.note);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create Bank">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/banks"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Bank Name"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setBankName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Address"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setAddress
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Account Name"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setAccountName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Account Number"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setAccountNumber
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Ifsc Code"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setIfscCode
                                                            }
                                                      />

                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Note/Comment"
                                                            handleChange={
                                                                  setNote
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default BankCreate;
