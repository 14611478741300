import styled from "styled-components";

export const MetisMenu = styled.ul`
      padding: 0;

      li {
            a {
            }
      }
`;

export const MenuSeparator = styled.div`
      color: #68798b;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 700;
      font-size: 12px;
      font-weight: 400;
      opacity: 0.7;
      display: block;
      margin: 24px 0 3px 15px;
`;
