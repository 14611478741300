import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import $ from 'jquery';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import FormInput from '../../components/form/FormInput';

const RoleCreate = () => {
      const [permissions, setPermissions] = useState([]);
      const [selectedPermissions, setSelectedPermissions] = useState([]);
      const [loadingData, setLoadingData] = useState(false);
      const [roleName, setRoleName] = useState('');

      const token = JSON.parse(localStorage.getItem('token'));

      useEffect(() => {
            const loadData = async () => {
                  setLoadingData(true);
                  try {
                        const response = await axios.get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles/create`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`
                                    }
                              }
                        );
                        const initialPermissions =
                              response.data.permissions.map((permission) => ({
                                    ...permission,
                                    checked: false
                              }));
                        setPermissions(initialPermissions);
                  } catch (error) {
                        console.error('Error loading data:', error);
                  } finally {
                        setLoadingData(false);
                  }
            };

            loadData();
      }, []);

      const handleSubmit = async (e) => {
            e.preventDefault();

            const roleData = {
                  name: roleName,
                  permissions: selectedPermissions
            };

            try {
                  await axios
                        .post(
                              `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles/store`,
                              roleData,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result == 'success') {
                                    toast.success(response.data.message);
                                    $('form').trigger('reset');
                              }
                        })
                        .catch((error) => {
                              toast.error(error.message);
                        });
            } catch (error) {
                  toast.error('Error storing role data:', error);
            }
      };

      const handlePermissionChange = (permissionId) => (e) => {
            const updatedPermissions = permissions.map((permission) => ({
                  ...permission,
                  checked:
                        permission.id === permissionId
                              ? e.target.checked
                              : permission.checked
            }));
            setPermissions(updatedPermissions);

            const selectedPermissionIds = updatedPermissions
                  .filter((permission) => permission.checked)
                  .map((permission) => permission.id);
            setSelectedPermissions(selectedPermissionIds);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Create New Role">
                                          <LinkButton
                                                link="/dashboard/roles"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row className="g-3">
                                                <FormInput
                                                      title="Role Name"
                                                      classes={12}
                                                      handleChange={setRoleName}
                                                />

                                                <div className="row mt-3">
                                                      {!loadingData &&
                                                            permissions.length >
                                                                  0 &&
                                                            permissions.map(
                                                                  (
                                                                        permission
                                                                  ) => (
                                                                        <Col
                                                                              key={
                                                                                    permission.id
                                                                              }
                                                                              lg={
                                                                                    3
                                                                              }
                                                                        >
                                                                              <Form.Check
                                                                                    type="switch"
                                                                                    id={`custom-switch-${permission.id}`}
                                                                                    label={
                                                                                          permission.name
                                                                                    }
                                                                                    checked={
                                                                                          permission.checked
                                                                                    }
                                                                                    onChange={handlePermissionChange(
                                                                                          permission.id
                                                                                    )} // Pass permission ID as argument
                                                                              />
                                                                        </Col>
                                                                  )
                                                            )}
                                                </div>
                                          </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                          <button type="submit">
                                                Save Role
                                          </button>
                                    </Card.Footer>
                              </Card>
                        </Col>
                  </Form>
            </>
      );
};

export default RoleCreate;
