import { Route } from "react-router-dom";
import { AgentRequestList, AgentRequestShow } from "../backend/agent_requests";
import { AgentList, AgentShow } from "../backend/agents";

export const agentManages = [
      // Agent
      <Route path="/dashboard/agents" element={<AgentList />} />,
      <Route path="/dashboard/agents/:agentId/show" element={<AgentShow />} />,

      // Agent Request
      <Route path="/dashboard/agent_requests" element={<AgentRequestList />} />,
      <Route
            path="/dashboard/agent_requests/:agentId/show"
            element={<AgentRequestShow />}
      />,
];
