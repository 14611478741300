import { Route } from "react-router-dom";
import { BankCreate, BankEdit, BankList } from "../bank-manage/banks";
import {
      DepositRequestList,
      DepositRequestManage,
} from "../bank-manage/bank_deposits";

export const bankManages = [
      // Bank Manage
      <Route path="/dashboard/banks" element={<BankList />} />,
      <Route path="/dashboard/banks/create" element={<BankCreate />} />,
      <Route path="/dashboard/banks/:bankId/edit" element={<BankEdit />} />,

      // Deposit Request
      <Route
            path="/dashboard/deposit_requests"
            element={<DepositRequestList />}
      />,
      <Route
            path="/dashboard/deposit_requests/:depositId/manage"
            element={<DepositRequestManage />}
      />,
];
