import React from "react";
import CheckMultiplePermission from "../../roles/CheckMultiplePermission";
import { MenuSeparator } from "../../navigation/styles";
import MenuItem from "../../metis/MenuItem";
import MenuLink from "../../metis/MenuLink";
import { ProductIcon } from "../../dashboard";

const BankManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              "bank-list",
                              "bank-create",
                              "bank-edit",
                              "bank-show",
                              "deposit-request-list",
                              "deposit-request-edit",
                              "deposit-request-create",
                              "deposit-request-delete",
                              "top-up-list",
                              "top-up-edit",
                              "top-up-create",
                              "top-up-delete",
                        ]}
                  >
                        <MenuSeparator>Bank Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "bank-list",
                                    "bank-create",
                                    "bank-edit",
                                    "bank-show",
                                    "deposit-request-list",
                                    "deposit-request-create",
                                    "deposit-request-edit",
                                    "deposit-request-show",
                                    "top-up-list",
                                    "top-up-edit",
                                    "top-up-create",
                                    "top-up-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Bank Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/banks",
                                          "/dashboard/banks/create",
                                          "/dashboard/banks/:bankId/edit",
                                          "/dashboard/banks/:bankId/show",
                                          "/dashboard/deposit_requests",
                                          "/dashboard/deposit_requests/create",
                                          "/dashboard/deposit_requests/:depositId/manage",
                                          "/dashboard/deposit_requests/:depositId/show",
                                          "/dashboard/top_ups",
                                          "/dashboard/top_ups/create",
                                          "/dashboard/top_ups/:topUpId/edit",
                                          "/dashboard/top_ups/:topUpId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "bank-list",
                                                "bank-create",
                                                "bank-edit",
                                                "bank-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Bank List"
                                                link="/dashboard/banks"
                                                links={[
                                                      "/dashboard/banks",
                                                      "/dashboard/banks/create",
                                                      "/dashboard/banks/:bankId/edit",
                                                      "/dashboard/banks/:bankId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "deposit-request-list",
                                                "deposit-request-create",
                                                "deposit-request-edit",
                                                "deposit-request-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Deposit Requests"
                                                link="/dashboard/deposit_requests"
                                                links={[
                                                      "/dashboard/deposit_requests",
                                                      "/dashboard/deposit_requests/create",
                                                      "/dashboard/deposit_requests/:depositId/manage",
                                                      "/dashboard/deposit_requests/:depositId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "top-up-list",
                                                "top-up-create",
                                                "top-up-edit",
                                                "top-up-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Top Up List"
                                                link="/dashboard/top_ups"
                                                links={[
                                                      "/dashboard/top_ups",
                                                      "/dashboard/top_ups/create",
                                                      "/dashboard/top_ups/:topUpId/edit",
                                                      "/dashboard/top_ups/:topUpId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default BankManage;
