import { Route } from "react-router-dom";
import { RoleCreate, RoleEdit, RoleList } from "../backend/roles";
import { UserCreate, UserEdit, UserList, UserShow } from "../backend/users";

export const userManages = [
      // Roles
      <Route path="/dashboard/roles" element={<RoleList />} />,
      <Route path="/dashboard/roles/create" element={<RoleCreate />} />,
      <Route path="/dashboard/roles/:roleId/edit" element={<RoleEdit />} />,

      // User
      <Route path="/dashboard/users" element={<UserList />} />,
      <Route path="/dashboard/users/create" element={<UserCreate />} />,
      <Route path="/dashboard/users/:userId/edit" element={<UserEdit />} />,
      <Route path="/dashboard/users/:userId/show" element={<UserShow />} />,
];
