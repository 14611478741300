import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import CardBody from "../../common/CardBody";
import DeleteButton from "../../common/DeleteButton";
import { convertAmount, convertFloat } from "../../../data/durations";
import { InfoIcon } from "../../../icons";
import ViewButton from "../../common/ViewButton";

const DepositListBox = ({
      loading,
      items,
      totalItems,
      perPages,
      setLimit,
      currentPage,
      getData,
      loadData,
      setSearch,
      setBanks,
      setLoading,
}) => {
      const token = JSON.parse(localStorage.getItem("token"));

      const handleDelete = async (blog) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/airlines/${blog.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBanks((agents) =>
                                    agents.filter((item) => item.id !== blog.id)
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <CardBody
                        totalItems={totalItems}
                        perPages={perPages}
                        setLimit={setLimit}
                        currentPage={currentPage}
                        getData={getData}
                        setSearch={setSearch}
                        borderStyle={true}
                        border={true}
                  >
                        <thead>
                              <tr>
                                    <th width="50px">#</th>
                                    <th>Amount (INR)</th>
                                    <th>Deposit Type</th>
                                    <th>Deposit On</th>
                                    <th>Beneficiary Bank</th>
                                    <th>Processed Status</th>
                                    <th>Processed Date and Time</th>
                                    <th>Processed Remarks</th>
                                    <th width="100px">Action</th>
                              </tr>
                        </thead>

                        <tbody>
                              {!loading ? (
                                    items.length > 0 ? (
                                          items.map((item, index) => (
                                                <>
                                                      <tr key={index}>
                                                            <td>
                                                                  {item.index}
                                                            </td>
                                                            <td>
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              item.requested_amount
                                                                        )
                                                                  )}
                                                            </td>
                                                            <td>
                                                                  {item?.deposit_type ||
                                                                        "-"}
                                                            </td>
                                                            <td>
                                                                  {item?.date ||
                                                                        "-"}
                                                            </td>
                                                            <td>
                                                                  {item?.atlas_bank_name ||
                                                                        "-"}
                                                            </td>
                                                            <td
                                                                  className={`text-uppercase ${
                                                                        item?.status ===
                                                                        1
                                                                              ? "text-success"
                                                                              : item?.status ===
                                                                                2
                                                                              ? "text-muted"
                                                                              : "text-warning"
                                                                  }`}
                                                            >
                                                                  {item?.status_name ||
                                                                        "-"}
                                                            </td>
                                                            <td>
                                                                  {item?.payment_received ||
                                                                        "-"}
                                                            </td>
                                                            <td className="text-center">
                                                                  {item?.summary ? (
                                                                        <InfoIcon
                                                                              height="20px"
                                                                              width="20px"
                                                                        />
                                                                  ) : (
                                                                        "-"
                                                                  )}
                                                            </td>
                                                            <td className="text-center">
                                                                  <ViewButton
                                                                        link={`/dashboard/deposit_requests/${item.slug}/manage`}
                                                                        classes="me-2"
                                                                  />
                                                                  <DeleteButton
                                                                        item={
                                                                              item
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        setLoading={
                                                                              setLoading
                                                                        }
                                                                        handleDelete={
                                                                              handleDelete
                                                                        }
                                                                  />
                                                            </td>
                                                      </tr>
                                                </>
                                          ))
                                    ) : (
                                          <>
                                                <tr className="border-bottom">
                                                      <td
                                                            colSpan="100%"
                                                            className="text-danger"
                                                      >
                                                            There is no deposit
                                                            request found.
                                                      </td>
                                                </tr>
                                          </>
                                    )
                              ) : (
                                    <>
                                          <tr>
                                                <td
                                                      colSpan="100%"
                                                      className="text-center"
                                                >
                                                      Loading...
                                                </td>
                                          </tr>
                                    </>
                              )}
                        </tbody>
                  </CardBody>
            </>
      );
};

export default DepositListBox;
