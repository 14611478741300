import axios from "axios";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { Card, Col, Row } from "react-bootstrap";
import CardHeader from "../../components/common/CardHeader";
import BankListBox from "../../components/backend/BankListBox";
import LinkButton from "../../components/common/LinkButton";
import DepositListBox from "../../components/backend/DepositListBox";

const DepositRequestList = () => {
      const [loading, setLoading] = useState(false);
      const [deposits, setDeposits] = useState([]);
      const [totalItems, setTotalItems] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem("token"));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState("");

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/deposit_requests?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setDeposits(response.data.deposits);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/bank_lists?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`,
                  },
            })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setDeposits(response.data.deposits);
                              setTotalItems(response.data.totalItems);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Helmet>
                        <title>Deposit Request List</title>
                  </Helmet>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Deposit Request List">
                                          <LinkButton
                                                link="/dashboard/deposit_requests/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <DepositListBox
                                          loading={loading}
                                          items={deposits}
                                          totalItems={totalItems}
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          currentPage={currentPage}
                                          getData={getData}
                                          loadData={loadData}
                                          setLoading={setLoading}
                                          setSearch={setSearch}
                                          setBanks={setDeposits}
                                    />
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DepositRequestList;
