import React from "react";
import TableSearch from "../TableSearch";
import TableFooter from "../TableFooter";
import { Card } from "react-bootstrap";
import { TableBody, TableBorderBody, TableBorderLessBody } from "./style";

const CardBody = ({
      setLimit,
      perPages,
      totalItems,
      currentPage,
      getData,
      setSearch,
      children,
      borderStyle,
      border,
}) => {
      return (
            <>
                  <Card.Body>
                        <TableSearch
                              setLimit={setLimit}
                              setSearch={setSearch}
                        />

                        {!borderStyle ? (
                              <TableBody hover bordered>
                                    {children}
                              </TableBody>
                        ) : border ? (
                              <TableBorderBody>{children}</TableBorderBody>
                        ) : (
                              <TableBorderLessBody>
                                    {children}
                              </TableBorderLessBody>
                        )}

                        <TableFooter
                              perPages={perPages}
                              totalItems={totalItems}
                              currentPage={currentPage}
                              getData={getData}
                        />
                  </Card.Body>
            </>
      );
};

export default CardBody;
