import React, { useEffect, useState } from "react";
import {
      Card,
      CardBody,
      Col,
      ListGroup,
      ListGroupItem,
      Row,
      Spinner,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import CardHeader from "../../components/common/CardHeader";
import axios from "axios";
import toast from "react-hot-toast";
import { ItemShow } from "../../components/form";
import { MainRow } from "./style";
import { convertAmount, convertFloat } from "../../data/durations";
import LinkButton from "../../components/common/LinkButton";

const DepositRequestManage = () => {
      const { depositId } = useParams();
      const [loading, setLoading] = useState(false);
      const [deposit, setDeposit] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/deposit_requests/${depositId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setDeposit(response.data.deposit);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      const [loadingApprove, setLoadingApprove] = useState(false);
      const [loadingCancel, setLoadingCancel] = useState(false);

      const handleAccept = async () => {
            setLoadingApprove(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/deposit_requests/${depositId}`,
                        {
                              status: 1,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoadingApprove(false);
      };

      const handleReject = async () => {
            setLoadingCancel(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/dealer_requests/${depositId}/reject`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              loadData();
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoadingCancel(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <title>View Deposit Request</title>
                  </Helmet>

                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="View Deposit Request">
                                          <LinkButton
                                                title="Approve"
                                                loading={loadingApprove}
                                                width="100px"
                                                handleClick={handleAccept}
                                          />
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/deposit_requests"
                                          />
                                    </CardHeader>
                                    <CardBody>
                                          {!loading ? (
                                                <MainRow>
                                                      <Col lg={6}>
                                                            <div className="mb-1 ms-2">
                                                                  Deposit
                                                                  Request
                                                            </div>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Deposit Amount"
                                                                        value={`INR ${
                                                                              deposit?.requested_amount
                                                                                    ? convertAmount(
                                                                                            convertFloat(
                                                                                                  deposit?.requested_amount
                                                                                            )
                                                                                      )
                                                                                    : "-"
                                                                        }`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Deposit Type"
                                                                        value={
                                                                              deposit?.deposit_type ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Agent Bank Name"
                                                                        value={
                                                                              deposit?.agent_bank_name ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Agent Deposit Branch"
                                                                        value={
                                                                              deposit?.agent_deposit_branch ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Atlas Bank Name"
                                                                        value={
                                                                              deposit?.atlas_bank_name ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Atlas Bank Number"
                                                                        value={
                                                                              deposit?.atlas_account_number ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Transaction Id"
                                                                        value={
                                                                              deposit?.transaction_id ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Mobile No."
                                                                        value={
                                                                              deposit?.mobile_no ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Cheque Draw On Bank"
                                                                        value={
                                                                              deposit?.cheque_draw_on_bank ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Cheque Issue Date"
                                                                        value={
                                                                              deposit?.cheque_issue_date ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Cheque Number"
                                                                        value={
                                                                              deposit?.cheque_number ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ListGroupItem>
                                                                        <a
                                                                              href={
                                                                                    deposit?.image
                                                                              }
                                                                              download={
                                                                                    true
                                                                              }
                                                                              target="_blank"
                                                                        >
                                                                              Download
                                                                              File
                                                                        </a>
                                                                  </ListGroupItem>
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <div className="mb-1 ms-2">
                                                                  Processed
                                                                  Detail
                                                            </div>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Processed Amount"
                                                                        value={`INR ${
                                                                              deposit?.processed_amount
                                                                                    ? convertAmount(
                                                                                            convertFloat(
                                                                                                  deposit?.processed_amount
                                                                                            )
                                                                                      )
                                                                                    : "-"
                                                                        }`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Processed Date"
                                                                        value={
                                                                              deposit?.payment_received ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Processed Remarks"
                                                                        value={
                                                                              deposit?.summary ||
                                                                              "-"
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Processed Status"
                                                                        value={
                                                                              <span
                                                                                    className={`text-uppercase ${
                                                                                          deposit?.status ===
                                                                                          1
                                                                                                ? "text-success"
                                                                                                : deposit?.status ===
                                                                                                  2
                                                                                                ? "text-muted"
                                                                                                : "text-warning"
                                                                                    }`}
                                                                              >
                                                                                    {deposit?.status_name ||
                                                                                          "-"}
                                                                              </span>
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </MainRow>
                                          ) : (
                                                <div className="text-center py-5">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DepositRequestManage;
